<template>
  <b-card title="Controllo vincitore">
    <div class="avvento-check" v-if="finish && user.length > 0">
      <p><img width="300px" :src="prize[0].image"></p>
      <p><b>{{prize[0].title}}</b></p>
      <p>{{prize[0].description}}</p>
      <!-- <b-alert
        variant="warning"
        :show="isEnded(user[0].day_win)"
      >
        <h4 class="alert-heading">
          ATTENZIONE IL BUONO E' SCADUTO
        </h4>
      </b-alert> -->
      <b-alert
        variant="warning"
        :show="user[0].date_used != null"
      >
        <h4 class="alert-heading">
          ATTENZIONE IL BUONO E' GIA' STATO UTILIZZATO
        </h4>
      </b-alert>
      <b-alert
        variant="warning"
        :show="user[0].day_win != current_day"
      >
        <h4 class="alert-heading">
          <!-- ATTENZIONE!!! IL BUONO NON E' DI OGGI, BUONO DEL {{user[0].day_win}}, OGGI E' IL {{current_day}} -->
          ATTENZIONE! BUONO NON PIU' VALIDO!
        </h4>
      </b-alert>
      <b-card-text>
        <b>Nome e cognome:</b> {{ user[0].name }}
      </b-card-text>
      <b-card-text>
        <b>Telefono:</b> {{ user[0].telephone }}
      </b-card-text>
      <b-card-text>
        <b>Email:</b> {{ user[0].email }}
      </b-card-text>
      <b-card-text>
        <b>Giorno premio:</b> {{ user[0].day_win }}
      </b-card-text>
      <b-card-text>
        <b>Utilizzo:</b> <span v-html="getDate(user[0].date_used)"></span>
      </b-card-text>
      <input type="text" placeholder="Paese del ristorante" v-model="restaurant" class="input-restaurant"><br>
      <b-button
        v-if="isButtonVisible()"
        variant="gradient-success"
        @click="setUsed(user[0].telephone, user[0].day_win)"
      >
        CONTRASSEGNA COME UTILIZZATO
      </b-button>
      <div
        v-if="loading"
      >
      <b-spinner
        variant="primary"
        class="spinner-def"
      />
    </div>
    </div>
    <div
      v-if="user.length <= 0"
      class="text-danger"
    >
      Nessuna corrispondenza trovata.
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BCardText, BAlert, BButton, BSpinner,
} from 'bootstrap-vue'
import 'vue-good-table/dist/vue-good-table.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BSpinner,
    BCardText,
    BAlert,
    BButton,
  },
  data() {
    return {
      user: [],
      prize: {},
      finish: false,
      loading: false,
      restaurant: '',
      current_day: null,
    }
  },
  mounted() {
    this.checkUser()
    this.current_day = parseInt(window.moment().format('DD'), 10)
  },
  methods: {
    isButtonVisible() {
      let x = false
      console.log(this.current_day)
      if (this.user[0].day_win >= 24) {
        if (this.user[0].date_used == null) {
          x = true
        }
      } else if (this.user[0].day_win === this.current_day) {
        if (this.user[0].date_used == null) {
          x = true
        }
      }
      return x
    },
    getAssociatedPrize(day) {
      this.$http.get(`/avvento/prize/${day}`).then(res => {
        this.prize = res.data
        this.finish = true
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Errore nel leggere il premio.',
            icon: 'AlertCircleIcon',
            variant: 'warning',
          },
        })
      })
    },
    getDate(date) {
      if (date !== null && date !== undefined) {
        if (date.trim() !== '') {
          return window.moment(date).format('DD/MM/YYYY')
        }
      }
      return '<b class="text-success"> NON ANCORA UTILIZZATO </b>'
    },
    setUsed(phone, day) {
      this.loading = true
      this.$http.post(`/avvento/check/${phone}/${day}`, { restaurant: this.restaurant }).then(() => {
        this.user = []
        this.loading = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Premio contrassegnato come utilizzato.',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.checkUser()
      }).catch(() => {
        this.loading = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Errore nel contrassegnare il premio.',
            icon: 'AlertCircleIcon',
            variant: 'warning',
          },
        })
      })
    },
    checkUser() {
      const urlSearchParams = new URLSearchParams(window.location.search)
      const params = Object.fromEntries(urlSearchParams.entries())
      //  const email = atob(params.user)
      const { day } = params
      const { telephone } = params
      this.$http.get(`/avvento/check/${telephone}/${day}`).then(response => {
        this.user = response.data
        this.getAssociatedPrize(day)
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Errore nel recupero dell\'utente.',
            icon: 'AlertCircleIcon',
            variant: 'warning',
          },
        })
      })
    },
  },
}
</script>

<style>
.mb-15{
  margin-bottom: 15px;
}
.avvento-check{
  text-align: center;
}
.avvento-check img{
  max-width: 100%;
}
*:not(input.field) {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
input {
  -webkit-user-select: text!important;
}
.input-restaurant{
  color: black;
  border: 1px solid #999999;
  padding: 5px 10px;
  margin-bottom: 20px;
  border-radius: 8px;
  min-width: 300px;
  text-align: center;
}
</style>
